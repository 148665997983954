import {
    MR_TM_ASE_FORM,
    ASE_INTERNAL_TICKET_STATUS_NEW,
    ASE_INTERNAL_TICKET_STATUS_CLOSED,
    ASE_INTERNAL_TICKET_STATUS_TRANSFERRED,
    ASE_INTERNAL_TICKET_STATUS_ANSWERED,
    ASE_INTERNAL_TICKET_STATUS_IN_PROGRESS,
    ASE_INTERNAL_TICKET_STATUS_REOPENED,
    ASE_TICKET_STATUS_NEW,
    ASE_TICKET_STATUS_IN_PROGRESS_DEALER,
    ASE_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
    ASE_TICKET_STATUS_READ_BY_SUBSIDIARY,
    ASE_TICKET_STATUS_REOPENED,
    ASE_TICKET_STATUS_CLOSED, ASE_TICKET_STATUS_TRANSFERRED, ASE_INTERNAL_TICKET_FORM
} from './formConstants';

export const ALL_COUNTRIES_VALUE = 'ALL';
export const SORT_ASC = 'asc';
export const SORT_DESC = 'desc';
export const SORT_NONE = '';
export const REQUEST_PARAM_DELIM = ':';

export const domains = {
    ADMINISTRATION: 'ADMINISTRATION',
    ASE: 'ASE',
};

export const languages = ['bs', 'cs', 'da', 'de', 'en', 'es', 'fr', 'hr', 'hu', 'it', 'nl', 'pl', 'pt', 'ro', 'ru', 'ru_UA', 'sk', 'sl', 'sr', 'sv', 'gsw', 'de_AT', 'es_AR', 'es_MX', 'tr'];

export const countryCurrency = {
    AR: 'ARS',
    CH: 'CHF',
    CZ: 'CZK',
    DK: 'DKK',
    FI: 'SEK',
    HU: 'HUF',
    MA: 'MAD',
    NO: 'SEK',
    PL: 'PLN',
    RO: 'RON',
    SE: 'SEK',
    GB: 'GBP',
    RU: 'RUB',
    MX: 'MXN',
    UA: 'UAH',
    DEFAULT: 'EUR'
};

export const locations = [
    'cs-CZ',
    'de-DE',
    'en-GB',
    'en-IE',
    'es-ES',
    'fr-FR',
    'it-IT',
    'pl-PL',
    'pt-PT',
    'sl-SI',
    'sv-SE',
    'es-AR',
    'es-MX',
    'uk-UA',
    'sq-XK',
    'tr-TR',
    'en-IN',
];

export const countryGroups = {
    AR: 'AR',
    AT: 'AT',
    BA: 'BA',
    BE: 'BE',
    CH: 'CH',
    CZ: 'CZ',
    DE: 'DE',
    DK: 'DK',
    DZ: 'DZ',
    EE: 'EE',
    ES: 'ES',
    FR: 'FR',
    IT: 'IT',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    MA: 'MA',
    ME: 'ME',
    MK: 'MK',
    MX: 'MX',
    NL: 'NL',
    PL: 'PL',
    PT: 'PT',
    RO: 'RO',
    RU: 'RU',
    SE: 'SE',
    AL: 'AL',
    SK: 'SK',
    GB: 'GB',
    UA: 'UA',
    XK: 'XK',
    IMP: 'IMP',
    IAG: 'IAG',
    IEU: 'IEU',
    IIN: 'IIN',
    TR: 'TR',
    IN: 'IN',
    CO: 'CO',
    BR: 'BR',
    KR: 'KR',
    IL: 'IL',
    BG: 'BG',
    ZA: 'ZA',
    SI: 'SI',
    HR: 'HR',
    GR: 'GR',
    IS: 'IS',
    RS: 'RS',
    IE: 'IE',
    AU: 'AU',
    HU: 'HU',
    NO: 'NO',
    FI: 'FI',
};

export const importerCountries = [
    'LK',
    'TD',
    'CY',
    'GE',
    'IR',
    'LY',
    'MT',
    'NE',
    'PH',
    'SY',
    'UY',
    'GT',
    'BM',
    'BO',
    'CL',
    'PE',
    'MD',
];

export const arabicGulfImporterCountries = [
    'JO',
    'OM',
    'QA',
    'AE',
    'SA',
    'BH',
    'KW',
    'LB',
    'PS',
    'IQ',
    'EG',
    'BY',
    'KZ',
    'MN',
    'AM',
    'AZ',
    'UZ',
];

export const europeImporterCountries = [
    'GF',
    'GP',
    'MQ',
    'RE',
];

export const internationalImporterCountries = [
    'AO',
    'BJ',
    'BN',
    'BF',
    'BI',
    'CM',
    'CV',
    'KM',
    'CG',
    'CU',
    'CD',
    'DJ',
    'ET',
    'PF',
    'GA',
    'GM',
    'GH',
    'GN',
    'HK',
    'ID',
    'CI',
    'JP',
    'KE',
    'LR',
    'MG',
    'MW',
    'MY',
    'ML',
    'MR',
    'MU',
    'YT',
    'MZ',
    'NC',
    'NZ',
    'NG',
    'RW',
    'PM',
    'SN',
    'SC',
    'SG',
    'SD',
    'TW',
    'TZ',
    'TH',
    'TG',
    'TN',
    'UG',
    'VN',
    'ZM',
    'ZW',
];

export const importerGroupsMapping = {
  IMP: importerCountries,
  IAG: arabicGulfImporterCountries,
  IIN: internationalImporterCountries,
  IEU: europeImporterCountries,
};

export function getCurrentFormTypes(domain) {
    return getFormTypes(domain);
}

export function getFormTypes(domain) {
    switch (domain) {
        case domains.ASE: {
            return [
                MR_TM_ASE_FORM
            ];
        }
        default: {
            return [];
        }
    }
}

export function getFormStates(domain) {
    switch (domain) {
        case domains.ASE: {
            return [
                ASE_TICKET_STATUS_NEW,
                ASE_TICKET_STATUS_IN_PROGRESS_DEALER,
                ASE_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
                ASE_TICKET_STATUS_READ_BY_SUBSIDIARY,
                ASE_TICKET_STATUS_REOPENED,
                ASE_TICKET_STATUS_CLOSED,
                ASE_TICKET_STATUS_TRANSFERRED,
            ]
        }
        default: {
            return [];
        }
    }
}

export function getInternalTicketFormStates(domain) {
    switch (domain) {
        case domains.ASE: {
            return [
                ASE_INTERNAL_TICKET_STATUS_NEW,
                ASE_INTERNAL_TICKET_STATUS_CLOSED,
                ASE_INTERNAL_TICKET_STATUS_TRANSFERRED,
                ASE_INTERNAL_TICKET_STATUS_ANSWERED,
                ASE_INTERNAL_TICKET_STATUS_IN_PROGRESS,
                ASE_INTERNAL_TICKET_STATUS_REOPENED,
            ];
        }
        default: {
            return [];
        }
    }
}

export const modules = {
    ADMINISTRATION: domains.ADMINISTRATION,
    ASE: 'ASE',
    BVM: 'BVM'
};

export const rootCauses = [
  'WRONG_OR_NOT_COMPLETE',
  'MISSING_DOCUMENTATION',
  'INCLUDE_OR_NOT_INCLUDE_ERROR',
  'APPLICATION_CODE_ERROR',
  'MISSING_OR_WRONG_LINK',
  'MISSING_OR_NOT_COMPLETE_ILLUSTRATION',
  'EXPLODED_VIEW_NOT_CLEAR',
  'DOCUMENTATION_IS_CORRECT',
  'PUBLICATION_DELAY',
  'TRANSLATION_ERROR',
];

export const issueOrigins = [
    'REPAIR_METHOD',
    'LABOR_TIME',
];

export const customerEffects = [
    'MISSING',
    'NOT_CORRECT',
    'WRONG_TRANSLATION',
    'EASY_BASKET_NOT_COMPLETED'
];

export const customerRisk = [
    'NONE',
    'SAFETY',
    'QUALITY'
];

export const immobilizedVehicle = [
    'YES',
    'NO',
];

export const REPAIR_METHOD = 'REPAIR_METHOD';
export const LABOR_TIME = 'LABOR_TIME';

export const issueOrigin = [
    REPAIR_METHOD,
    LABOR_TIME,
];

export const ASE = 'ASE';
export const ADD = 'ADD';
export const CIT = 'CIT';

export const TECHLINE = "Techline";
export const WARRANTY = "WARRANTY";
//choice list : ASE/ADD/CIT/ASe/Techline

export const internalTicketRole = [
    ASE,
    ADD,
    CIT,
    TECHLINE,
    WARRANTY,
];

export const priorityTreatment = [
    'YES',
    'NO',
];

export const VEHICLE_SAFETY_IMPACT = "VEHICLE_SAFETY_IMPACT"
export const OTS_QUALITY_BLOCAGE = "OTS_QUALITY_BLOCAGE"
export const ACTIS_REPAIR_METHOD = "ACTIS_REPAIR_METHOD"

export const VOR_PVI = 'VOR / PVI';

export const priorityCases = [
    VEHICLE_SAFETY_IMPACT,
    OTS_QUALITY_BLOCAGE,
    ACTIS_REPAIR_METHOD,
    VOR_PVI
];

export const PRIORITY_SRC = 'SRC';
export const PRIORITY_VOR = 'VOR';
export const PRIORITY_SRC_VOR = 'SRC + VOR';

export const priorityType = [
    PRIORITY_SRC,
    PRIORITY_VOR,
    PRIORITY_SRC_VOR,
];

export const requestForPartner = [
    'YES',
    'NO',
];

export const documentationUpdate = [
    'YES',
    'NO',
];

export function getInternalFormTypes(domain) {
    switch (domain) {
        case domains.ASE: {
            return [
                ASE_INTERNAL_TICKET_FORM
            ];
        }
        default: {
            return [];
        }
    }
}

export const YES = 'YES';
export const NO = 'NO';
export const PRESIDENT = 'PRESIDENT';

export const automaticAseResponseOptions = {};
